<template>
  <div>
    <PageHeader
      titleHeader="Galeria"
      subtitleHeader="Surpreenda seu paladar com sabores excepcionais"
    />
    <div style="margin: 0px 30px 0px 30px">
      <div class="row">
        <div
          class="col-md-4 body-on"
          v-for="n in 245"
          :key="n"
          :title="'código: b' + n"
        >
          <div class="sabores_item">
            <span class="sabores_item_title">({{ n }})</span>
            <hr />
            <a
              :id="'b' + n"
              :href="'./img/galeria/b' + n + '.jpg'"
              :data-lightbox="'bolo' + n"
              :title="'código: b' + n"
              :data-title="'bolo' + n"
            >
              <b-img-lazy
                style="border-radius: 10px"
                :src="'./img/galeria/b' + n + '.jpg'"
                :title="'código: b' + n"
                height="200"
                width="250"
                :data-lightbox="'bolo' + n"
                :data-title="'bolo' + n"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/layout/PageHeader";

export default {
  name: "Galeria",
  components: {
    PageHeader,
  },
};
</script>
